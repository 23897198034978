<template>
    <div>
        <el-container>
            <el-header class="site-header" height="auto">
                <siteheader parentName="Home"></siteheader>
            </el-header>

            <el-breadcrumb class="navMenu" separator-class="el-icon-arrow-right">
                <el-breadcrumb-item
                    icon="el-icon-location"
                    v-for="item in breadcrumbItems"
                    :key="item.text"
                    :to="item.to"
                >{{item.text}}</el-breadcrumb-item>
            </el-breadcrumb>

            <el-main>
                <h3>Manage Locations</h3>
                <div id="homeContent" v-if="hasAuth">
                    <mapTab activeTab="locations"></mapTab>
                    <el-button type="text" style="float:right" @click.native="onNewPoi">Add New POI</el-button>
                    <el-table :data="pois" style="width: 100%">
                        <el-table-column fixed prop="facilityName" label="Name" width="150"></el-table-column>
                        <el-table-column prop="location.latitude" label="Latitude" width="120"></el-table-column>
                        <el-table-column prop="location.longitude" label="Longitude" width="120"></el-table-column>
                        <el-table-column prop="categories" label="Category"></el-table-column>
                        <el-table-column prop="streetAddress" label="Address"></el-table-column>
                        <el-table-column prop="website" label="Website"></el-table-column>
                        <el-table-column prop="phoneNumber" label="Phone"></el-table-column>
                        <el-table-column fixed="right" label="Operations" width="120">
                            <template slot-scope="scope">
                                <el-button
                                    @click.native.prevent="onEditPOI(scope.$index, tableData)"
                                    type="text"
                                    size="small"
                                >Edit</el-button>
                                <el-button
                                    @click.native.prevent="onDeletePOI(scope.$index, tableData)"
                                    type="text"
                                    size="small"
                                >Remove</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-main>
        </el-container>
    </div>
</template>

<script>
import siteheader from '../components/site-header'
import mapTab from '../components/mapTab'
import utils from '../services/utils.js'
import moment from 'moment'
import jquery from 'jquery'

export default {
    name: 'Locations',
    mixins: [utils],
    components: {
        siteheader: siteheader,
        mapTab: mapTab,
    },
    props: [],
    data() {
        return {
            baseURL: `${this.$store.state.webservice}/poi/`,
            dialogVisible: false,
            keyword: '',
            pois: [],
            activedPOI: {
                location: {
                    latitude: null,
                    longitude: null,
                },
            },
            breadcrumbItems: [
                {
                    text: 'Home',
                    active: false,
                },
                {
                    text: 'Manage Locations',
                    active: true,
                },
            ],
            loginUser: {},
            isAdmin: false,
            loginOrg: {},
            isCtaop: false,
        }
    },
    mounted() {
        this.loginUser = this.getLoginUser()
        this.isAdmin = this.loginUser.userType == 'Admin'
        this.loginOrg = this.getLoginOrg()
        this.isCtaop = this.loginOrg.uuid == 'ctaopUuid'

        this.loadPOI()
    },
    computed: {
        hasAuth() {
            return this.hasAccessToken()
        },
        userRole() {
            return this.loginUserType()
        },
        path() {
            return this.$router.currentRoute.path
        },
    },

    methods: {
        onTabClick(tab, evt) {
            if (tab != this.ativeTabName) {
                var login = this.getLoginUser()
                this.$router.push({
                    name: 'map',
                    params: { loginUser: login },
                    query: { orgUuid: login.orgUuid },
                })
            }
        },
        loadPOI() {
            this.doGet(this.baseURL, (resp, data) => {
                this.pois = data
            })
        },
        onNewPoi() {
            this.$router.push({ name: 'poiEditor', params: { uuid: "" } })
        },
        onEditPOI(index) {
            let uuid = this.pois[index].uuid
            this.$router.push({ name: 'poiEditor', params: { uuid: uuid } })
        },
        onDeletePOI(index) {
            let self = this
            this.$confirm('Are you sure to delete this location?')
                .then(_ => {
                    let uuid = this.pois[index].uuid
                    this.doDelete(`${this.baseURL}${uuid}`, resp => {
                        self.loadPOI()
                    })
                })
                .catch(_ => {})
        },
        handleError(err) {
            // this.log('error: ' + err)
            this.errMessage = err
            this.notifyError(err)
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
