<template>
    <div class="site-menu">
        <el-row :gutter="10">
            <el-col :span="2" class="homelink">
                <el-button type="text" v-if="true" icon="el-icon-back" @click="goBack">Back</el-button>
            </el-col>
            <el-col :span="2" class="homelink">
                <!-- spacer -->
            </el-col>
            <el-col :span="16" class="headline">
                <h1>{{this.getLoginOrg().name}}</h1>
            </el-col>

            <el-col :span="2" class="profilelink">
                <!-- <router-link v-show="isHome" :to="{ name : 'Profile', params: {item: this.loginUser}, query: {uuid: this.loginUser.uuid} }" class="icon-settings"></router-link> -->

                <el-dropdown @command="handleSetting" v-show="isHome" trigger="click">
                    <span>
                        <i class="icon-settings"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="a">My Profile</el-dropdown-item>
                        <!-- <el-dropdown-item command="b" v-if="isAdmin">Manage Organizations</el-dropdown-item> -->
                        <el-dropdown-item command="c" v-if="isAdmin">Manage Users</el-dropdown-item>
                        <el-dropdown-item command="d" v-if="isAdmin">Manage Locations</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </el-col>

            <el-col :span="2" class="logoutlink">
                <a class="icon-logout" title="Logout" @click="doLogout"></a>
            </el-col>
        </el-row>
    </div>
</template>


<script>
import utils from '../services/utils.js'

export default {
    name: 'site-header',
    mixins: [utils],
    props: ['parentName'],
    data() {
        return {
            useRouter: true,
            loginUser: {},
            loginOrg: {},
            isAdmin: false,
            isCtaop: false,
        }
    },
    created() {
        if (!this.hasAuth) {
            this.$router.push('/')
        }
        this.loginUser = this.getLoginUser()
        this.loginOrg = this.getLoginOrg()
        this.isAdmin = this.loginUser.userType == 'Admin'
        this.isCtaop = this.loginOrg.uuid == 'ctaopUuid'
    },
    mounted() {},
    computed: {
        activeView() {
            return this.$router.currentRoute.path
        },
        hasAuth() {
            return this.hasAccessToken()
        },
        loginUser() {
            return this.getLoginUser()
        },
        isHome() {
            return this.$router.currentRoute.name == 'home'
        },
    },
    methods: {
        doLogout() {
            //  		this.$store.commit('clearUser');
            this.removeLoginUser()
            this.$nextTick(function() {
                this.$router.replace('/')
            })
        },
        goBack() {
            this.$router.go(-1)
        },
        handleSetting(command) {
            switch (command) {
                case 'a':
                    this.$router.push({
                        name: 'User',
                        params: { item: this.loginUser },
                        query: { uuid: this.loginUser.uuid },
                    })
                    break
                case 'b':
                    this.$router.push('orgs')
                    break
                case 'c':
                    {
                        var login = this.getLoginUser()
                        this.$router.push({
                            name: 'users',
                            params: { loginUser: login },
                            query: { orgUuid: login.orgUuid },
                        }) // passing object to next vue
                    }
                    break
                case 'd':
                    {
                        var login = this.getLoginUser()
                        this.$router.push({
                            name: 'locations',
                            params: { loginUser: login },
                            query: { orgUuid: login.orgUuid },
                        })
                    }
                    break
                default:
                    break
            }
        },
    },
}
</script>


<style>
.site-menu {
    width: 100%;
    background-color: #e5e5e5;
    min-height: 63px;
}

.headline {
    text-align: center;
}

.homelink {
    padding-top: 8px;
    padding-left: 8px;
}
.profilelink {
    padding-top: 8px;
}
.logoutlink {
    padding-top: 8px;
    padding-right: 8px;
}

.icon-home {
    background-image: url('../assets/home3.svg');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 32px;
    width: 48px;
    height: 48px;
    display: block;
    cursor: pointer;
}

.icon-settings {
    background-image: url('../assets/cog.svg');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 32px;
    width: 48px;
    height: 48px;
    display: block;
    cursor: pointer;
}

.icon-logout {
    background-image: url('../assets/exit.svg');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 32px;
    width: 48px;
    height: 48px;
    display: block;
    cursor: pointer;
}
</style>