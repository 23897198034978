<template>
<div>
  <el-container>

    <el-header class="site-header" height="auto">
        <siteheader></siteheader>
    </el-header>


    <el-main>
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span  style="text-align: left">{{user.firstname}} {{user.lastname}}</span>
          <button v-on:click="updateOrg">My Organization</button>
        </div>

        <div class="line"></div>

        <el-form ref="form" @submit.native.prevent="updateProfile">
          <h3>Update Profile</h3>

          <span>
          <el-alert type="error"  v-if="errMessage.length" :title="errMessage"></el-alert>
          </span>

          <el-form-item label="User Type">
            <el-input placeholder="role" v-bind:readonly="userType != 'Admin'" v-model="userForm.userType"></el-input>
          </el-form-item>

          <el-form-item label="Username">
            <el-input placeholder="Username" v-model="userForm.uname"></el-input>
          </el-form-item>
          <el-form-item label="Password">
            <el-input placeholder="Password" v-model="userForm.pwdHash"></el-input>
          </el-form-item>
          <el-form-item label="First Name">
            <el-input placeholder="First Name" v-model="userForm.firstname"></el-input>
          </el-form-item>
          <el-form-item label="Last Name">
            <el-input placeholder="Last Name" v-model="userForm.lastname"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" :loading="loading" native-type="submit" >Update Profile</el-button>
          </el-form-item>


        </el-form>


      </el-card>

    </el-main>

  </el-container>
</div>
</template>

<script>
import siteheader from '../components/site-header'
import utils from '../services/utils.js'

export default {
  name: 'Profile',
  mixins: [utils],
  components : {
    siteheader,

  },
  props: ['item'],
  data () {
    return {
      errMessage: '',
      loading: false,
      userForm : {
        userType: this.$store.getters.userData.userType,
        uname: this.$store.getters.userData.uname,
        firstname: this.$store.getters.userData.firstname,
        lastname: this.$store.getters.userData.lastname,
        pwdHash: ''
      },
     user: this.item, // init with the prop passed by the from-component}
      org: {}
    }
  },
  
  mounted() {
	  console.log(userForm);
  },
  computed: {
    /*
    user() {
      return this.$store.getters.userData
    }
  */
  },
  methods : {
    updateOrg() {
      alert('updateOrg');
    },
    updateProfile() {
      this.loading = true;
      var updateProfileEndpoint = this.$store.state.webservice + '/updateUser';
      var body = this.user;
      body.firstname = this.userForm.firstname;
      body.lastname = this.userForm.lastname;
      body.uname = this.userForm.uname;
      body.userType = this.UserForm.userType;
      
      var str = this.userForm.pwdHash;
      if(str && str.length > 0) {
        body.pwdHash = str;
      }
      
      delete body.hasAuth; // need to move this attribute to non-webservice request object, otherwise object serialization will fail. 

      var config = {
        headers : {
          'accessToken' : this.user.accessToken,
        }
      };
      
        this.$http.post(updateProfileEndpoint, body, config).then(response => {

          this.loading = false;
          if (response.body.rc == 1) {
            try {
              var user = response.body.data;
              user.hasAuth = true; // TODO: better make it a computed field from accessToken
              this.$store.commit('updateUser', user);             
              this.$router.push('/')
            } 
            catch (err) {
              console.log('error: ' + err)
              this.handleError(err);
            } 

          } else {
          	console.log('error: ' + response)
            this.handleError('An Error Occured: ' + response.body.data);
          }

        }, response => {
          // error callback
          console.log('error: ')
            this.handleError(response);
        });

    },
    handleError(err) {
      //console.log('error: ' + err)
      this.errMessage = err
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.el-card {
  width: 100%;
}


</style>
