<template>
<div>
  <el-container>

    <el-header class="site-header" height="auto">
        <siteheader parentName="Home"></siteheader>
    </el-header>

    <el-breadcrumb class="navMenu" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item icon="el-icon-location" v-for="item in breadcrumbItems"  :key="item.text" :to="item.to">{{item.text}}</el-breadcrumb-item>
    </el-breadcrumb>    

    <el-main>

      <div id="homeContent" v-if="hasAuth">

      	<el-form ref="form" :model="formData" label-width="120px">
      		<el-form-item label="To Phone"><el-input v-model="formData.toPhone"></el-input></el-form-item>
      		<el-form-item label="Search Categories">
      			<el-select v-model="formData.categories" multiple placeholder="Select"  style="width:360px">
      				<el-option
				      v-for="item in categoryOptions"
				      :key="item.value"
				      :label="item.label"
				      :value="item.value">
				    </el-option>
				</el-select>    
			</el-form-item>      			      			

			<el-form-item label="Title" v-if="false"><el-input v-model="formData.textTitle"></el-input></el-form-item>

      		<el-form-item label="Languages">
  			<el-select v-model="formData.lang" placeholder="Select" @change="onLangSelected">
  				<el-option
			      v-for="item in languages"
			      :key="item.value"
			      :label="item.label"
			      :value="item.value">
			    </el-option>
			</el-select>    
			</el-form-item>      			      			
			
			<el-form-item label="Preview"><el-input type="textarea" v-model="formData.text1" readonly=true></el-input></el-form-item>
			
			<el-form-item>
				<el-button type="primary" @click="onSubmit">Create</el-button>
				<el-button>Cancel</el-button>
			</el-form-item>  
		</el-form>      
      

      </div>

    </el-main>

  </el-container>
</div>
</template>

<script>
import siteheader from '../components/site-header'
import utils from '../services/utils.js'
import moment from 'moment'
import jquery from 'jquery'

export default {
  name: 'Home',
  mixins: [utils],
  components : {
    'siteheader': siteheader,
  },
  props: [],
  data () {
    return {
      msg: '',
      breadcrumbItems: [{
        text: 'Home',
        active: true
      }],
      loginUser: {},
      isAdmin: false,
      loginOrg: {},
      isCtaop: false,
      formData: {
    	  requesterUuid: '',
    	  toPhone: '',
    	  textTitle: '',
    	  text1: '',
// preset: 'Preset 1',
// qs: '',
    	  categories:[],
    	  lang: 'English'
      },
      languages: [      
    	  {
              value: 'English',
              label: 'English',
              message: 'Here\'s a map you may find useful: https://bit.ly/zxcvb Please turn on your mobile data service and ALLOW location permission when asked to view this zero-rated map'
          },
          {
              value: 'Zulu',
              label: 'Zulu',
              message: 'Nansi I nkombandlela ezokusiza: https://bit.ly/zxcvb Vula amaData kumakhala ekhukhwini wakho nemvumo yokuthi kuvele nendawo lapho ukhona ukuze uzobona izindawo eziseduze nawe lapho ungathola khona usizo'
          },
    	  {
              value: 'Xhosa',
              label: 'Xhosa',
              message: 'Nantsi imephu onokuyifumana uncedo:   https://bit.ly/zxcvb   Nceda uvule inkonzo yakho yedatha yeselula kwaye UVUMELE imvume yendawo xa ucelwa ukuba ujonge le mephu inexabiso elinguziro'
          },
          {
              value: 'Afrikaans',
              label: 'Afrikaans',
              message: 'Hier is \'n kaart wat jy dalk nuttig kan vind: https://bit.ly/zxcvb  Skakel asseblief jou mobiele datadiens aan en LAAT liggingtoestemming toe wanneer jy gevra word om hierdie nulkoerskaart te bekyk'
          }
      ],
      categoryOptions: [
            	{
                    value: 'SRH',
                    label: 'General Sexual and Reproductive Health Services',
                },
                {
                    value: 'CFP',
                    label: 'Comprehensive Family Planning Services',
                },
                {
                    value: 'GBV',
                    label: 'Comprehensive Gender-Based Violence Provider',
                },
                {
                    value: 'PRC',
                    label: 'Post-Rape Care',
                }
            ]
    }
  },
  mounted() {
    this.loginUser = this.getLoginUser();
    this.isAdmin = this.loginUser.userType == 'Admin';
    this.loginOrg = this.getLoginOrg();
    this.isCtaop = (this.loginOrg.uuid == 'ctaopUuid');
    this.formData.requesterUuid = this.loginUser.uuid;
    this.onPresetChanged(this.formData.preset);
  },
  computed: {
    hasAuth() {
      return this.hasAccessToken();
    },
    userRole() {
      return this.loginUserType();
    },
    path() {
      return this.$router.currentRoute.path;
    },
  },
  
  methods : {
    onPresetChanged(newValue) {
// if(newValue === 'Preset 1') {
// this.formData.qs = 'SRH'
// } else if(newValue === 'Preset 2') {
// this.formData.qs = 'CFP'
// } else if(newValue === 'Preset 3') {
// this.formData.qs = 'SRHCFP'
// }
    	
    	// After today's talk, here's a map you may find useful:
		// https://wtc.datafree.co/wtc/view/map.html?uuid=f6eb8b83-aeda-4568-9445-5e0422fc4e5a
//    	newValue = 'After today\'s talk, here\'s a map you may find useful:';    	
//    	this.formData.text1 = newValue + ' https://bit.ly/zxcvb';
    	this.formData.text1 = this.languages[0]['message'];
    	
	},
	
	onLangSelected(evt) {
		console.log('onLangSelected: ' + evt);
		
//		var desc = this.languages[0]['message'];
//		if (this.formData.lang == this.languages[1]['value']) { // Zulu
//			desc = 'Emva kwengxoxo yanamhlanje nansi I nkombandlela ezokusiza:';
//		}
		
//		var idx = this.formData.lang == this.languages[1]['value'] ? 1 : 0 ;

		var idx = -1;
		var lang = this.formData.lang
		this.languages.forEach(function (item, index) {
		  if(item.value == lang) { 
			  idx = index 
		  }
		});

		var desc = this.languages[idx]['message'];

    	this.formData.text1 = desc;
	},
	
	onSubmit() {
	  console.log('submit!' + this.formData);
	  // POST sendMapSms
	  // JsonResponse sendMapSms(MapSmsRequest request,
		// @HeaderParam("accessToken") String accessToken)
	  
	  if (this.formData.toPhone.length == 0) {
		  this.handleError("Please enter Phone number")
	  } else if (this.formData.categories == null || this.formData.categories.length == 0) {
		  this.handleError("Please select Search categories")
	  } else {
		  var url = this.$store.state.webservice + '/sendMapSms';
	      var pthis = this;
	      this.formData.requesterUuid = this.loginUser.uuid;
	      this.doPost(url, this.formData, function(resp, data) {
	          try {
	            pthis.notifyMessage("Sent");
	          } catch (err) {
	            pthis.log('error: ' + err)
	            pthis.handleError(err);
	          } 
	        }, function(resp, err) {
	          pthis.log('error: ' + err)
	          pthis.handleError('An Error Occured: ' + resp.body.data);
	        })
	  }
	},
	 
    showOrgUpsert() {
      var o = this.loginOrg;           
      this.$router.push({name: 'PpnOrg', params: {item: o}, query: {uuid: o.uuid}}); 
    },

    handleError(err) {
      // this.log('error: ' + err)
      this.errMessage = err;
      this.notifyError(err);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

.fontSizelarge {
    font-size: large;
}

#homeContent {
  margin: auto;
  text-align: center;
}

.el-breadcrumb {
  padding: 20px;
}



</style>
