import Vue from 'vue'
import Router from 'vue-router'

import home from './views/Home.vue'
import about from './views/About.vue'
import login from './views/Login.vue'
import profile from '@/views/Profile'
import users from '@/views/Users'
import User from '@/views/User'
import Locations from '@/views/Locations'
import GoogleMap from '@/views/GoogleMap'
import PoiEditor from '@/views/PoiEditor'

Vue.use(Router)

export default new Router({
    routes: [
        {
            path: '/',
            name: 'login',
            component: login,
        },
        {
            path: '/home',
            name: 'home',
            component: home,
        },
        {
            path: '/profile',
            name: 'profile',
            component: profile,
        },
        {
            path: '/users',
            name: 'users',
            component: users,
            props: true,
        },
        {
            path: '/user',
            name: 'User',
            component: User,
            props: true,
        },
        {
            path: '/about',
            name: 'about',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "about" */ './views/About.vue'),
        },
        {
            path: '/locations',
            name: 'locations',
            component: Locations,
        },
        {
            path: '/locations/map',
            name: 'map',
            component: GoogleMap,
        },
        {
            path: '/locations/new',
            name: 'poiEditor',
            component: PoiEditor,
        },
        {
            path: '/locations/:uuid',
            name: 'poiEditor',
            component: PoiEditor,
        },
    ],
})
