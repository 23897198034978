<template>
    <el-tabs :value="activeTab" @tab-click="onTabClick">
        <el-tab-pane label="Locations" name="locations"></el-tab-pane>
        <el-tab-pane label="Map" name="map"></el-tab-pane>
    </el-tabs>
</template>
<script>
export default {
    name: 'mapTab',
    props: ['activeTab'],
    methods: {
        onTabClick(tab, evt) {
            if (tab && tab !== this.activeTabName) {
                this.$router.replace(tab)
            }
        },
    },
}
</script>