import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        // state is not persisted upon page reload. not too useful
        user: {},
        webservice: '/wtc/api',
    },
    getters: {
        userData: state => {
            //    	return state.user
            var str = window.sessionStorage.getItem('u')
            if (str) {
                var u = JSON.parse(str)
                return u
            } else {
                return null
            }
        },
        hasAuth: state => {
            //return state.accessToken && getters.accessToken.length;
            var str = window.sessionStorage.getItem('u')
            if (str) {
                var u = JSON.parse(str)
                var token = u.accessToken
                return token && token.length
            } else {
                return false
            }
        },
        role: state => {
            //    	return state.user.userType;
            var str = window.sessionStorage.getItem('u')
            if (str) {
                var u = JSON.parse(str)
                var role = u.userType
                return role
            } else {
                return null
            }
        },
    },
    mutations: {
        updateUser(state, obj) {
            window.sessionStorage.setItem('u', JSON.stringify(obj))
            state.user = obj
        },
        clearUser(state, obj) {
            state.user = {
                accessToken: '',
                uuid: '',
                uname: '',
                firstname: '',
                lastname: '',
                userType: '',
                orgUuid: '',
            }
            window.sessionStorage.removeItem('u')
            console.log('User data cleared')
        },
    },
})
