
<template>
<div>
  <el-container>

    <el-header class="site-header" height="auto">
        <siteheader></siteheader>
    </el-header>

    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item icon="el-icon-location" v-for="item in breadcrumbItems" :key="item.text" :to="item.to">{{item.text}}</el-breadcrumb-item>
    </el-breadcrumb>    
    
    <el-main>
    
      <el-alert type="error"  v-if="errMessage.length" :title="errMessage"></el-alert>
      
      <div class="detail-view">
      
       <el-card class="box-card">
           <div slot="header" class="clearfix">
             <h3>{{title}}</h3>
           </div>
      
           <div class="line"></div>
      
           <el-form ref="form" @submit.native.prevent="doSaveOrg" :label-position="Left" label-width="190px">
          
             <el-form-item label="Name"><el-input placeholder="name" v-model="orgData.name"></el-input></el-form-item>
             <el-form-item label="address"><el-input placeholder="address" type="textarea" :autosize="{minRows: 2}" v-model="orgData.address"></el-input></el-form-item>
             
             <el-form-item label="First time received funding"> 
               <div>
                 <el-date-picker
                   v-model="orgData.firstFundingDate"
                   type="date"
                   value-format="timestamp"
                   placeholder="Pick a day">
                 </el-date-picker>
               </div>
             </el-form-item>
            
             <el-form-item label="Share Folder URL"><el-input placeholder="URL to share folder" v-model="orgData.shareFolderUrl"></el-input></el-form-item>

              <el-card class="box-card" v-if="orgData.uuid">
                <div slot="header" class="clearfix">
                  <span>Head of Organization</span>
                </div>
              
                <el-form-item label="Name"><el-input placeholder="Head of Organization Name" v-model="orgData.headName"></el-input></el-form-item>
                <el-form-item label="Title"><el-input placeholder="Head of Organization Title" v-model="orgData.headTitle"></el-input></el-form-item>
                <el-form-item label="Email"><el-input placeholder="Head of Organization Email" v-model="orgData.headEmail"></el-input></el-form-item>
                <el-form-item label="Telephone"><el-input placeholder="Head of Organization Telephone" v-model="orgData.headPhone"></el-input></el-form-item>
              
              </el-card>             
                                                  
            <el-card class="box-card" v-if="orgData.uuid">
              <div slot="header" class="clearfix">
                <span>CTAOP-Supported Programs</span>
                <el-button style="float: right;" type="primary" icon="el-icon-plus" plain @click="showAddProgram(-1, {})" v-if="isLoginUserOrgCtaop">Add</el-button>
              </div>
              
              <el-table stripe :data="orgData.programs" :default-expand-all="false" @row-click="showAddProgram2">    
                <el-table-column type="expand">
                  <template slot-scope="props">
                    <p align="left">{{props.row.description}}</p>
                  </template>                
                </el-table-column>
                <el-table-column prop="name" label="Name" align="left"></el-table-column>
                <el-table-column width="180" prop="targetPopulation" label="Target Population" align="center"></el-table-column>
                <el-table-column width="94" prop="startDate" :formatter="dateFormatter" label="Start Date" align="center"></el-table-column>
                <el-table-column width="94" prop="endDate" :formatter="dateFormatter" label="End Date" align="center"></el-table-column>
              </el-table>
              
              <ul type="1" start="1" style="text-align: left; margin-top:12px">
                <li><div>
                    <p>Does your organization have active, collaborative relationships with other organizations that assist in achieving the objectives of your CTAOP-supported program(s)?</p>
                    <el-radio-group v-model="orgData.bOrgCollaboration"> <el-radio :label="true">Yes</el-radio> <el-radio :label="false">No</el-radio> </el-radio-group>
                    <el-collapse-transition>
                    <div v-show="orgData.bOrgCollaboration">
                      <ol type="a">
                        <li>
                          <p>Please describe these collaborations. Who are the other organizations and how do you they support your current CTAOP-supported programs listed above?</p> 
                          <el-input placeholder="free text" type="textarea" :autosize="{minRows: 2}"
                            v-model="orgData.orgCollaborationDetail"
                          ></el-input>
                        </li>
                      </ol>
                    </div>
                    </el-collapse-transition>
            
                  </div></li>
              </ul>

            </el-card>
            <div>
                <b-modal ref="modalProgram" hide-footer v-bind:title="title + ' CTAOP-Supported Program'">
                  <div class="d-block text-center">
                  <el-form :label-position="Left" label-width="160px">
                    <el-form-item label="Name"><el-input placeholder="Program name" type="textarea" :readonly="!isLoginUserOrgCtaop" :autosize="{minRows: 1}" v-model="programToAdd.name"></el-input></el-form-item> 
                    <el-form-item label="Description"><el-input type="textarea" :autosize="{minRows: 2}" placeholder="Program description" v-model="programToAdd.description"></el-input></el-form-item>                       
                    <el-form-item label="Target Population"><el-input-number placeholder="Program target population" type="" v-model="programToAdd.targetPopulation"></el-input-number></el-form-item>                       
                    <el-form-item label="Start Date"> 
                      <div>
                        <el-date-picker
                          v-model="programToAdd.startDate"
                          type="date"
                          value-format="timestamp"
                          placeholder="Pick a day">
                        </el-date-picker>
                      </div>
                    </el-form-item>                       

                    <el-form-item label="End Date"> 
                      <div>
                        <el-date-picker
                          v-model="programToAdd.endDate"
                          type="date"
                          value-format="timestamp"
                          placeholder="Pick a day">
                        </el-date-picker>
                      </div>
                    </el-form-item>                       

                    <p>{{'Person responsible for ' + programToAdd.name}}</p>                       
                    <el-form-item label="Name"><el-input placeholder="Name of person responsible" type="" v-model="programToAdd.personName"></el-input></el-form-item>                       
                    <el-form-item label="Title"><el-input placeholder="Title of person responsible" type="" v-model="programToAdd.personTitle"></el-input></el-form-item>                       
                    <el-form-item label="Email"><el-input placeholder="Email of person responsible" type="" v-model="programToAdd.personEmail"></el-input></el-form-item>                       
                    <el-form-item label="Telephone"><el-input placeholder="Telephone of person responsible" type="" v-model="programToAdd.personPhone"></el-input></el-form-item>
                    </el-form>                       
                  </div>
                  
                  <b-container>
                   <b-row>
                     <b-col v-if="programToAdd.name && isLoginUserOrgCtaop"><el-button  type="danger" icon="el-icon-delete" block @click="doDeleteProgram">Delete</el-button ></b-col>
                     <b-col><el-button  type="primary" icon="el-icon-check" block @click="doSaveProgram">Save</el-button ></b-col>
                   </b-row>                            
                  </b-container>                  
                  
                  
                </b-modal>
             </div>
          
          <el-form-item label="">
          </el-form-item>
          
          <el-form-item label="Organization Admin" v-if="orgData.uuid && isAdmin">
             <b-input-group>
               <b-form-input readonly v-model="admin.uname"></b-form-input>
               <b-input-group-append>
                 <el-button @click="showAdminUpsert" type="primary" icon="el-icon-edit" plain v-if="isAdmin">Update</el-button>
               </b-input-group-append>
             </b-input-group>
          </el-form-item>
            
           <div>
              <b-modal ref="modalAdmin" hide-footer v-bind:title="title + ' Admin'">
                <div class="d-block text-center">
                  <el-form-item label="Username"><el-input placeholder="Username" v-model="adminToUpdate.uname"></el-input></el-form-item> 
                  <el-form-item label="Password"><el-input placeholder="Password" type="" v-model="adminToUpdate.pwdHash"></el-input></el-form-item>                       
                </div>
                <el-button class="mt-3" type="primary" icon="el-icon-check" block @click="doSaveAdmin">Save</el-button>
              </b-modal>
           </div>             

          <el-card class="box-card" v-if="orgData.uuid">
            <div slot="header" class="clearfix">
              <span>Baseline Narrative</span>
              <p></p>
            </div>
            <ol style="text-align: left;">
              <li><div>
                <p>From the date your organization first received funding from CTAOP through JUNE 30, 2017, what was the <b>total number of youth</b> served by CTAOP-supported programs?</p>
                <el-input-number v-model="orgData.youthServedBase" @change="" :min="0"></el-input-number>
              
                <ol type="a">
                  <li>
                    <p>Describe how you calculated the <b>total number of youth</b> served by CTAOP-supported programs through JUNE 30, 2017:</p> 
                    <el-input placeholder="free text" type="textarea" :autosize="{minRows: 2}" v-model="orgData.youthServedCalcBase"></el-input>
                  </li>
                </ol>
              </div></li>
              <li><div>
                <p>From the date your organization first received funding from CTAOP through JUNE 30, 2017, what was the <b>total number of people (youth and adults)</b> served by CTAOP-supported programs?</p>
                <el-input-number v-model="orgData.totalServedBase" @change="" :min="0"></el-input-number>
                <ol type="a">
                  <li><p>
                      Describe how you calculated the <b>total number of people (youth and adults)</b> served by CTAOP-supported programs through JUNE 30, 2017:
                    </p> <el-input placeholder="free text" type="textarea" :autosize="{minRows: 2}" v-model="orgData.totalServedCalcBase"></el-input></li>
                </ol>
              </div></li>
            </ol>
            
          </el-card>

          <el-form-item label=""></el-form-item>

          <b-container>
            <b-row>
              <b-col v-if="orgUuid"><el-button type="danger" icon="el-icon-delete" v-on:click="doDelete" v-if="isAdmin && isLoginUserOrgCtaop">Delete</el-button></b-col>
              <b-col><el-button type="primary" icon="el-icon-check" v-on:click="doSaveOrg">Save</el-button></b-col>
            </b-row>                            
          </b-container>
          </el-form>
        </el-card>

      </div>

    </el-main>

  </el-container>
</div>

</template>

<script>
import Vue from 'vue';
import siteheader from '../components/site-header';
import utils from '../services/utils.js'


export default {
  name: 'Org',
  mixins: [utils],
  components : {
    siteheader
  },
  props: ['item', 'uuid'],
  data () {
    return {
      msg: '',
      errMessage: '',
      orgData: {}, //this.item, // init with the prop passed by the from-component
      admin: {},
      adminToUpdate: {},
      programToAdd: {},
      programSelectedIndex: -1,
    }
  },
  computed: {
    title: function () {
      // `this` points to the vm instance
      if(this.orgData && this.orgData.name) {
        return this.orgData.name;
      } else {
        return "Enter new Org";
      }
    },
    orgUuid: function () {
      if(!this.orgData && !this.uuid) {
        var quuid = this.$route.query.uuid;
        this.uuid = quuid;
        return quuid;
      } else {
        return this.orgData.uuid;
      }
    },
    isAdmin: function() {
      var loginUser = this.getLoginUser();
      return loginUser.userType == 'Admin'; 
    },
    isLoginUserOrgCtaop: function() {
      var loginUser = this.getLoginUser();
      return loginUser.orgUuid == 'ctaopUuid'; 
    },
    breadcrumbItems: function() {
      var routeName = this.$route.name;
      
      var bcis;
      if(routeName == 'Org') {
        bcis = [{
          text: 'Home',
          to: 'Home'
        }, {
          text: 'Manage Organizations',
          to: 'orgs'
        }, {
          text: 'Organization Detail',
          active: true
        }];
      } else if (routeName == 'UserOrg') {
        bcis = [{
          text: 'Home',
          to: 'Home'
        }, {
          text: 'Manage Users',
          to: {"name": 'Users', "params": {"loginUser": this.getLoginUser()}, "query": {"orgUuid": this.getLoginUser().orgUuid}}
        }, {
          text: 'User',
          to: {"name": 'User', "params": {"item": this.admin}, "query": {"uuid": this.admin.uuid}}  
        }, {
          text: 'User Organization',
          active: true
        }];
      } else if(routeName == 'MyOrg'){ // MyOrg
        bcis = [{
          text: 'Home',
          to: 'Home'
        }, {
          text: 'My Profile',
          to: { "name" : 'Profile', "params": {"item": this.admin}, query: {uuid: this.getLoginUser().uuid} }
        }, {
          text: 'My Organization',
          active: true
        }];
      } else {
        bcis = [];
      }
      
      return bcis;
    }
  },
  created() {
    this.log(">>created");
  },
  mounted() {
    this.log(this.orgData);
    this.orgData = this.item;
    if(this.orgUuid) {
      this.getOrg(this.orgUuid);
    } else {
      this.notifyMessage("Create New Organization");
    }
  },
  methods : {

    showAdminUpsert() {
      this.adminToUpdate = Object.assign({}, this.admin);      
      this.adminToUpdate.pwdHash = "";
      this.$refs.modalAdmin.show();
    },
    
    doSaveAdmin() {    
      if(this.orgUuid) {
        this.adminToUpdate.orgUuid = this.orgUuid;
        this.adminToUpdate.userType = "Admin";
        this.doUpsertAdmin();
      } else {
        this.handleError('Please Save Orgnization First');
      }      
    },
    
    doUpsertAdmin() {
      this.log(this.admin);
      
      var url = this.$store.state.webservice + '/assignAdmin';
      var pSelf = this;
      this.doPost(url, this.adminToUpdate, function(resp, data) {
        pSelf.admin = data;
        pSelf.orgData.adminUuid = pSelf.admin.uuid;
        pSelf.$refs.modalAdmin.hide();
        pSelf.doSaveOrg();
      }, function(resp, err) {
        pSelf.log(resp);
        pSelf.handleError(err);
      });
    },

    getOrg(uuid) {
      this.log('getOrg:' + uuid)
      if(!uuid) {
        this.handleError('Invalid UUID.');
        return;
      }
      
      var url = this.$store.state.webservice + '/org?uuid=' + uuid;
      var pSelf = this;
      this.doGet(url, function(resp, data) {
        try {
          pSelf.orgData = data;
          var adminUuid = pSelf.orgData.adminUuid;
          if(adminUuid) {
            if(pSelf.isAdmin) {
              pSelf.getUser(adminUuid);
            }
          }
        } catch (err) {
          pSelf.log('error: ' + err)
          pSelf.handleError(err);
        }         
      }, function(resp, err) {
        pSelf.log('error: ' + resp)
        pSelf.handleError('An Error Occured: ' + resp.body.data);
      })
    },
    
    getUser(uuid) {
      this.log('getUser:' + uuid)
      if(!uuid) {
        this.handleError('Invalid Admin User UUID.');
        return;
      }
      
      var url = this.$store.state.webservice + '/user?uuid=' + uuid;
      
      var pthis = this;
      this.doGet(url, function(resp, data) {
        try {
          if(data) {
            pthis.admin = data;
          }
        } catch (err) {
          pthis.log('error: ' + err)
          pthis.handleError(err);
        }       
      }, function(resp, err) {
        pthis.log('error: ' + resp)
        pthis.handleError('An Error Occured: ' + resp.body.data);
      });
    },
    
    doSaveOrg() {
      this.log(this.orgData);
      
      var url = this.$store.state.webservice + '/upsertOrg';
      
      var pthis = this;
      this.doPost(url, this.orgData, function(resp, data) {
        try {
          pthis.orgData = data;              
          //this.$router.go(-1);
          pthis.notifyMessage("Saved");
          pthis.$refs.modalProgram.hide();
        } catch (err) {
          pthis.log('error: ' + err)
          pthis.handleError(err);
        } 
      }, function(resp, err) {
        pthis.log('error: ' + resp)
        pthis.handleError('An Error Occured: ' + resp.body.data);
      })
    },
    
    showAddProgram2: function(row, event, column) {
      var p = row;
      var idx = event.currentTarget.rowIndex;
      this.showAddProgram(idx, p);
    },
    
    showAddProgram: function(idx, p) {
      this.log(this.orgData);
      this.programSelectedIndex = idx;
      this.programToAdd = Object.assign({}, p);
      this.$refs.modalProgram.show();
    },
    
    doSaveProgram: function() {
      this.log(this.programToAdd);
      
      var newObj = this.programToAdd;      
      if(!newObj.name) {
        this.handleError('Program name required');
        return;
      }

      var programs = this.orgData.programs;
      if(!programs) {
        programs = [];
      }
      
      //var idx = programs.findIndex((obj => obj.name == newObj.name));
      var idx = this.programSelectedIndex;
      if(idx >=0) {
        programs[idx] = newObj;
      } else {
        programs.push(newObj);
      }

      this.orgData.programs = programs;
      this.programToAdd = {};
      this.log(this.orgData);

      this.doSaveOrg();
    },
    
    doDeleteProgram: function() {
      this.log(this.programToAdd);
            
      if(this.programSelectedIndex >=0) {
        this.orgData.programs.splice(this.programSelectedIndex, 1)
        this.doSaveOrg();
      }
    },
    
    doDelete: function (event) {
      this.log(this.orgData);
      
      var url = this.$store.state.webservice + '/deleteOrg';
      var pthis = this;
      this.doPost(url, this.orgData, function(resp, data) {
        try {
//          pthis.orgData = resp.body.data;  
          pthis.notifyMessage("Deleted");
          pthis.$router.go(-1);
        } catch (err) {
          pthis.log('error: ' + err)
          pthis.handleError(err);
        } 
      }, function(resp, err) {
        pthis.log('error: ' + resp)
        pthis.handleError('An Error Occured: ' + resp);
      })
    },
    
    dateFormatter(row, column, cellValue, index) {
      this.log(row);
      return this.timestampToDateString(cellValue);
    },
    
    handleError(msg) {
      // this.log('error: ' + err)
      this.errMessage = msg;
      this.notifyError(msg);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
  

</style>
