import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import VueResource from 'vue-resource'
import VueSessionStorage from 'vue-sessionstorage'
import Vuex from 'vuex'

import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/en'

import 'element-ui/lib/theme-chalk/index.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.config.productionTip = false

Vue.use(VueResource)
Vue.use(VueSessionStorage)
Vue.use(Vuex)

Vue.use(ElementUI, { locale })

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')
