<template>
    <div>
        <el-container>
            <el-header class="site-header" height="auto">
                <siteheader></siteheader>
            </el-header>

            <el-main>
                <el-form
                    class="text-left"
                    ref="form"
                    @submit.native.prevent="doSavePOI"
                    label-position="Left"
                    label-width="180px"
                >
                    <el-form-item label="Facility Name">
                        <el-input
                            placeholder="Facility Name"
                            v-model="poi.facilityName"
                            type="text"
                            autocomplete="off"
                        ></el-input>
                    </el-form-item>
                    
                    <el-row>
	                    <el-col :span="12">
		                    <el-form-item label="Stars">
		                    <el-rate v-model="poi.stars" style="float: left; margin-top: 10px;"></el-rate>
	                    </el-form-item>
	                    <el-col :span="12">
		                    <el-form-item label="Verification Status">
		                    <el-radio v-model="poi.verificationStatus" label="Where to Care VERIFIED">Where to Care VERIFIED</el-radio>
		                    <el-radio v-model="poi.verificationStatus" label="Not VERIFIED">Not VERIFIED</el-radio>
		                    <el-radio v-model="poi.verificationStatus" label="VERIFIED but does NOT meet Where to Care Standards">VERIFIED but does NOT meet Where to Care Standards</el-radio>
		                    </el-form-item>
	                    </el-col>
                    </el-row>
                    
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="Category">
								<el-select v-model="poi.categories" multiple placeholder="Select" style="width:350px">
								    <el-option
								      v-for="item in categoryOptions"
								      :key="item.value"
								      :label="item.label"
								      :value="item.value">
								    </el-option>
								</el-select>    
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="Type">
                                <el-radio v-model="poi.type" label="Government">Government</el-radio>
                                <el-radio v-model="poi.type" label="NGO or CBO">NGO or CBO</el-radio>
                                <el-radio v-model="poi.type" label="Private">Private</el-radio>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="Latitude">
                                <el-input
                                    placeholder="latitude"
                                    v-model="poi.location.latitude"
                                    type="text"
                                    autocomplete="off"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="Longitude">
                                <el-input
                                    placeholder="longitude"
                                    v-model="poi.location.longitude"
                                    type="text"
                                    autocomplete="off"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    
                    <el-form-item label="Country">
                    	<el-row>
                    	<el-col :span="8">
	                        <el-select v-model="poi.country" placeholder="Select" style="width:300px">
						    <el-option
						      v-for="item in countryMap"
						      :key="item.name"
						      :label="item.name"
						      :value="item.name">
						    </el-option>
						    </el-select>
                    	</el-col>
                    	</el-row>
                    </el-form-item>

                    <el-form-item label="State/Province">
                	<el-row>
                	<el-col :span="8">
	                    <el-select v-model="poi.state" placeholder="Select" style="width:300px">
					    <el-option
					      v-for="item in stateOptions()"
					      :key="item.nama"
					      :label="item.name"
					      :value="item.name">
					    </el-option>
					    </el-select>
                	</el-col>
                	</el-row>                    
				    </el-form-item>

				    <el-form-item label="District">
	            	<el-row>
	            	<el-col :span="8">
	                    <el-select v-model="poi.district" placeholder="Select" style="width:300px">
					    <el-option
					      v-for="item in districtOptions()"
					      :key="item.name"
					      :label="item.name"
					      :value="item.name">
					    </el-option>
					    </el-select>
	            	</el-col>
	            	</el-row>
	                </el-form-item>                    

	                <el-form-item label="Subdist">	                
		            	<el-row>
		            	<el-col :span="8">
		                    <el-select v-model="poi.subdist" placeholder="Select" style="width:300px">
						    <el-option
						      v-for="item in subdistOptions()"
						      :key="item"
						      :label="item"
						      :value="item">
						    </el-option>
						    </el-select>
		            	</el-col>
		            	</el-row>
	                </el-form-item>

                    <el-form-item label="Street Adress1">
	                    <el-input
	                        placeholder="Street address"
	                        v-model="poi.streetAddress"
	                        type="text"
	                        autocomplete="off"
	                    ></el-input>
	                </el-form-item>
	                <el-form-item label="Street Adress2">
	                    <el-input
	                        placeholder="address line 2 (optional)"
	                        v-model="poi.streetAddress2"
	                        type="text"
	                        autocomplete="off"
	                    ></el-input>
	                </el-form-item>
	                <el-form-item label="City">
	                    <el-input
	                        placeholder="City"
	                        v-model="poi.city"
	                        type="text"
	                        autocomplete="off"
	                    ></el-input>
	                </el-form-item>
                    
                    <el-form-item label="Post Code">
                        <el-input
                            placeholder="Post Code"
                            v-model="poi.postCode"
                            type="text"
                            autocomplete="off"
                        ></el-input>
                    </el-form-item>

                    <el-form-item label="Phone Number">
                        <el-row>
                            <el-col :span="3" class="mr-2">
                                <el-input
                                    placeholder="+"
                                    v-model="poi.countryTelCode"
                                    type="text"
                                    autocomplete="off"
                                ></el-input>
                            </el-col>
                            <el-col :span="8">
                                <el-input
                                    placeholder="phone Number"
                                    v-model="poi.phoneNumber"
                                    type="text"
                                    autocomplete="off"
                                ></el-input>
                            </el-col>
                        </el-row>
                    </el-form-item>

                    
                    <el-form-item label="Phone Number2">
	                    <el-row>
	                        <el-col :span="6">
	                            <el-input
	                                placeholder="alt. phone number"
	                                v-model="poi.phoneNumber2"
	                                type="text"
	                                autocomplete="off"
	                            ></el-input>
	                        </el-col>
	                        <el-col :span="6">
	                            <el-input
	                                placeholder="alt. phone number purpose"
	                                v-model="poi.phoneNumber2Note"
	                                type="text"
	                                autocomplete="off"
	                                style="margin-left: 20px"
	                            ></el-input>
	                        </el-col>
	                    </el-row>
	                </el-form-item>

                    
                    
					<el-row>
                        <el-col :span="8">
		                    <el-form-item label="Days Open">
		                    	<el-select v-model="poi.daysOpen" multiple placeholder="Select Days">
								    <el-option
								      v-for="item in daysOpenOptions"
								      :key="item.value"
								      :label="item.label"
								      :value="item.value">
								    </el-option>
							  	</el-select>     
		                    </el-form-item>
                        </el-col>
                        <el-col :span="16">
							<el-form-item label="Available Hours">
								<el-input type="textarea" :rows="2" placeholder="Available Hours or notes" v-model="poi.daysOpenNotes">
								</el-input>														
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-form-item label="Website">
                        <el-input
                            placeholder="Website"
                            v-model="poi.website"
                            type="text"
                            autocomplete="off"
                        ></el-input>
                    </el-form-item>

					<el-form-item label="Fees for service">
					<el-col :span="8">
                        <el-radio v-model="poi.feesForService" :label=true>Yes</el-radio>
                        <el-radio v-model="poi.feesForService" :label=false>No</el-radio>
					</el-col>
					<el-col :span="16">
						<el-input placeholder="free text" v-model="poi.feesForServiceText"></el-input>
					</el-col>
				</el-form-item>
				
<el-collapse-transition>
<div  v-show="isGov()">
                    <el-form-item label="Happy hours offered">
						<el-col :span="8">
	                        <el-radio v-model="poi.happyHoursOffered" :label=true>Yes</el-radio>
	                        <el-radio v-model="poi.happyHoursOffered" :label=false>No</el-radio>
						</el-col>
						<el-col :span="16">
							<el-input placeholder="free text" v-model="poi.timesAndDaysOfYouthFriendlyHappyHours"></el-input>
						</el-col>
                    </el-form-item>
</div></el-collapse-transition>

                    <el-form-item label="Date of last Where to Care verification of services">
		                 <el-col :span="8">                    	
		                 <div class="block">
		                    <el-date-picker v-model="poi.monthYearWhereToCareVerificationOfServices" type="month" placeholder="Pick a month" format="yyyy MMM" value-format="yyyy MMM">
		                    </el-date-picker>
		                  </div>  
		                  </el-col>
                    </el-form-item>


<p>
SERVICES PROVIDED: Select ALL that apply
</p>


<ul style='list-style-type:none'>
	<li>
	<el-col :span="8">
		<el-checkbox label="Medical Termination of Pregnancy" v-model="poi.mtopServicesProvided"></el-checkbox>
	</el-col>
	<el-col :span="16">
		<el-input placeholder="free text" v-model="poi.mtopServicesProvidedNote"></el-input>
	</el-col>
	</li>

	<li>
	<el-col :span="8">
		<el-checkbox label="Surgical Termination of Pregnancy" v-model="poi.stopServicesProvided"></el-checkbox>
	</el-col>
	<el-col :span="16">
		<el-input placeholder="free text" v-model="poi.stopServicesProvidedNote"></el-input>
	</el-col>
	</li>
</ul>


<el-checkbox-group v-model="poi.servicesProvided">
	<ol type='a'>
	<li><el-checkbox label="Pregnancy Counselling & Testing"></el-checkbox></li>
	<li><el-checkbox label="HIV Testing & Counselling"></el-checkbox></li>
	<li><el-checkbox label="STI Testing & Counselling"></el-checkbox></li>
	<li><el-checkbox label="Condoms"></el-checkbox></li>
	<li><el-checkbox label="Emergency Contraception/PlanB"></el-checkbox></li>
	<li><el-checkbox label="PrEP (PreExposure Prophylaxis)"></el-checkbox></li>
	<li><el-checkbox label="PEP (Post Exposure Prophylaxis)"></el-checkbox></li>
	<li><el-checkbox label="VMMC (Voluntary Medical Male Circumcision)"></el-checkbox></li>
	<li><el-checkbox label="ART onsite"></el-checkbox></li>
	<li><el-checkbox label="Pill"></el-checkbox></li>
	<li><el-checkbox label="Implant"></el-checkbox></li>
	<li><el-checkbox label="Injection"></el-checkbox></li>
	<li><el-checkbox label="Patch"></el-checkbox></li>
	<li><el-checkbox label="Loop/IUD"></el-checkbox></li>
	<li><el-checkbox label="Medical Termination of Pregnancy"></el-checkbox></li>
	<li><el-checkbox label="Surgical Termination of Pregnancy"></el-checkbox></li>
	<li><el-checkbox label="Non-discrimination policy to serve youth"></el-checkbox></li>
	<li><el-checkbox label="Non-discrimination policy to serve LGBTQI"></el-checkbox></li>
	<li><el-checkbox label="Non-discrimination policy to serve Sex Workers"></el-checkbox></li>
	<li><el-checkbox label="Non-discrimination policy to serve People Who Use Illegal Drugs"></el-checkbox></li>
	<li><el-checkbox label="Patient Confidentiality Policy"></el-checkbox></li>
	<li><el-checkbox label="Serves patients in private rooms"></el-checkbox></li>
	<li><el-checkbox label="Supplying legal counsel or court advocates - GBV"></el-checkbox></li>
	<li><el-checkbox label="Help filing injunctions or restraining orders - GBV"></el-checkbox></li>
	<li><el-checkbox label="Immigration support for non-South African survivors - GBV"></el-checkbox></li>
	<li><el-checkbox label="GBV Counselling & psychosocial support- GBV"></el-checkbox></li>
	<li><el-checkbox label="Substance abuse services"></el-checkbox></li>
	<li><el-checkbox label="GBV Shelter"></el-checkbox></li>
	<li><el-checkbox label="GBV Shelter for those < 18"></el-checkbox></li>
	<li><el-checkbox label="GBV Family services for children of survivors (child care, child friendly violence services)"></el-checkbox></li>
	<li><el-checkbox label="GBV Injury assessment & treatment"></el-checkbox></li>
	<li><el-checkbox label="GBV Post assault care: Emergency contraception, HIV & STI testing, PEP"></el-checkbox></li>
	<li><el-checkbox label="GBV Forensic sample collection / Rape Kit"></el-checkbox></li>
	</ol>
</el-checkbox-group>

                    <el-row>
                        <el-col class="text-right">
                            <button type="button" v-on:click="doSavePOI" class="btn btn-primary m-2">Save</button>
                            <button type="button" v-on:click="goBack" class="btn btn-danger m-2">Cancel</button>
                        </el-col>
                    </el-row>
                </el-form>
            </el-main>
        </el-container>
    </div>
</template>

<style scoped>
</style>

<script>
import siteheader from '../components/site-header'
import utils from '../services/utils.js'
import jquery from 'jquery'

export default {
    name: 'PoiEditor',
    mixins: [utils],
    components: {
        siteheader: siteheader,
    },
    props: [],
    data() {
        var self = this
        return {
            baseURL: `${this.$store.state.webservice}`,
            poi: {
                location: {
                    latitude: null,
                    longitude: null,
                },
                daysOpen: [],
                categories: [],
            },
            addressOptions: {
                lazy: true,
                lazyLoad(node, resolve) {
                    if (node.root) {
                        self.doGet(`${self.baseURL}/address`, (resp, data) => {
                            resolve(data)
                        })
                    } else {
                        self.doGet(`${self.baseURL}/address/${node.value}`, (resp, data) => {
                            resolve(data)
                        })
                    }
                },
            },
            servicesProvided: [],
            daysOpenOptions: [
                {
                    value: 'Sunday',
                    label: 'Sunday',
                },
                {
                    value: 'Monday',
                    label: 'Monday',
                },
                {
                    value: 'Tuesday',
                    label: 'Tuesday',
                },
                {
                    value: 'Wednesday',
                    label: 'Wednesday',
                },
                {
                    value: 'Thursday',
                    label: 'Thursday',
                },
                {
                    value: 'Friday',
                    label: 'Friday',
                },
                {
                    value: 'Saturday',
                    label: 'Saturday',
                },
            ],
            
            categoryOptions: [
            	{
                    value: 'SRH',
                    label: 'General Sexual and Reproductive Health Services',
                },
                {
                    value: 'CFP',
                    label: 'Comprehensive Family Planning Services',
                },
                {
                    value: 'GBV',
                    label: 'Comprehensive Gender-Based Violence Provider',
                },
                {
                    value: 'PRC',
                    label: 'Post-Rape Care',
                }
            ],
            countryMap: [
            	{ 
            		name: 'South Africa',
            		states: [
	        			{
	        				name: 'Eastern Cape',
	        				districts: [
	        					{
	        						name: 'Alfred Nzo District',
	        						subdists: ['Matatiele Local Municipality',
	        							'Mbizana Local Municipality',
	        							'Ntabankulu Local Municipality',
	        							'Umzimvubu Local Municipality']
	        					}, 
	        					{
	        						name: 'Amathole District',
	        						subdists: ['Amahlathi Local Municipality',
	        							'Great Kei Local Municipality',
	        							'Mbhashe Local Municipality',
	        							'Mnquma Local Municipality',
	        							'Ngqushwa Local Municipality',
	        							'Raymond Mhlaba Local Municipality']
	        					}, 
	        					{
	        						name: 'Chris Hani District',
	        						subdists: ['Emalahleni Local Municipality',
	        							'Engcobo Local Municipality',
	        							'Enoch Mgijima Local Municipality',
	        							'Intsika Yethu Local Municipality',
	        							'Inxuba Yethemba Local Municipality',
	        							'Sakhisizwe Local Municipality']
	        					}, 
	        					{
	        						name: 'Joe Gqabi District',
	        						subdists: ['Elundini Local Municipality',
	        							'Senqu Local Municipality',
	        							'Walter Sisulu Local Municipality']
	        					}, 
	        					{
	        						name: 'OR Tambo District',
	        						subdists: ['Ingquza Hill Local Municipality',
	        							'King Sabata Dalindyebo Local Municipality',
	        							'Mhlontlo Local Municipality',
	        							'Nyandeni Local Municipality',
	        							'Port St Johns Local Municipality']
	        					}, 
	        					{
	        						name: 'Sarah Baartman District',
	        						subdists: ['Blue Crane Route Local Municipality',
	        							'Dr Beyers Naudé Local Municipality',
	        							'Kouga Local Municipality',
	        							'Koukamma Local Municipality',
	        							'Makana Local Municipality',
	        							'Ndlambe Local Municipality',
	        							'Sundays River Valley Local Municipality']
	        					}, 
	        					{
	        						name: 'Buffalo City Metropolitan',
	        						subdists: []
	        					}, 
	        					{
	        						name: 'Nelson Mandela Bay Metropolitan',
	        						subdists: []
	        					}
	        				]
	        			},
	        			{
	        				name: 'Free State',
	        				districts: [
	        					{
	        						name: 'Fezile Dabi District',
	        						subdists: ['Mafube Local Municipality',
	        							'Metsimaholo Local Municipality',
	        							'Moqhaka Local Municipality',
	        							'Ngwathe Local Municipality']
	        					},{
	        						name: 'Lejweleputswa District',
	        						subdists: ['Masilonyana Local Municipality',
	        							'Matjhabeng Local Municipality',
	        							'Nala Local Municipality',
	        							'Tokologo Local Municipality',
	        							'Tswelopele Local Municipality']
	        					},{
	        						name: 'Thabo Mofutsanyana District',
	        						subdists: ['Dihlabeng Local Municipality',
	        							'Maluti-A-Phofung Local Municipality',
	        							'Mantsopa Local Municipality',
	        							'Nketoana Local Municipality',
	        							'Phumelela Local Municipality',
	        							'Setsoto Local Municipality']
	        					},{
	        						name: 'Xhariep District',
	        						subdists: ['Kopanong Local Municipality',
	        							'Letsemeng Local Municipality',
	        							'Mohokare Local Municipality']
	        					},{
	        						name: 'Mangaung Metropolitan',
	        						subdists: []
	        					},
	        				]
	        			},
	        			{
	        				name: 'Gauteng',
	        				districts: [
	        					{
	        						name: 'Sedibeng District',
	        						subdists: ['Emfuleni Local Municipality',
	        							'Lesedi Local Municipality',
	        							'Midvaal Local Municipality']
	        					},{
	        						name: 'West Rand District',
	        						subdists: ['Merafong City Local Municipality',
	        							'Mogale City Local Municipality',
	        							'Rand West City Local Municipality']
	        					},{
	        						name: 'City of Ekurhuleni Metropolitan',
	        						subdists: []
	        					},{
	        						name: 'City of Johannesburg Metropolitan',
	        						subdists: []
	        					},{
	        						name: 'City of Tshwane Metropolitan',
	        						subdists: []
	        					},
	        				]
	        			},
	        			{
	        				name: 'KwaZulu Natal',
	        				districts: [
	        					{
	        						name: 'Amajuba District',
	        						subdists: ['Dannhauser Local Municipality',
	        							'eMadlangeni Local Municipality',
	        							'Newcastle Local Municipality']
	        					},{
	        						name: 'Harry Gwala District',
	        						subdists: ['Dr Nkosazana Dlamini Zuma Local Municipality',
	        							'Greater Kokstad Local Municipality',
	        							'Ubuhlebezwe Local Municipality',
	        							'uMzimkhulu Local Municipality']
	        					},{
	        						name: 'iLembe District',
	        						subdists: ['KwaDukuza Local Municipality',
	        							'Mandeni Local Municipality',
	        							'Maphumulo Local Municipality',
	        							'Ndwedwe Local Municipality']
	        					},{
	        						name: 'King Cetshwayo District',
	        						subdists: ['City of uMhlathuze Local Municipality',
	        							'Mthonjaneni Local Municipality',
	        							'Nkandla Local Municipality',
	        							'uMfolozi Local Municipality',
	        							'uMlalazi Local Municipality']
	        					},{
	        						name: 'Ugu District',
	        						subdists: ['Ray Nkonyeni Local Municipality',
	        							'Umdoni Local Municipality',
	        							'Umuziwabantu Local Municipality',
	        							'Umzumbe Local Municipality']
	        					},{
	        						name: 'uMgungundlovu District',
	        						subdists: ['Impendle Local Municipality',
	        							'Mkhambathini Local Municipality',
	        							'Mpofana Local Municipality',
	        							'Msunduzi Local Municipality',
	        							'Richmond Local Municipality',
	        							'uMngeni Local Municipality',
	        							'uMshwathi Local Municipality']
	        					},{
	        						name: 'uMkhanyakude District',
	        						subdists: ['Big 5 Hlabisa Local Municipality',
	        							'Jozini Local Municipality',
	        							'Mtubatuba Local Municipality',
	        							'uMhlabuyalingana Local Municipality']
	        					},{
	        						name: 'uMzinyathi District',
	        						subdists: ['Endumeni Local Municipality',
	        							'Msinga Local Municipality',
	        							'Nquthu Local Municipality',
	        							'Umvoti Local Municipality']
	        					},{
	        						name: 'uThukela District',
	        						subdists: ['Alfred Duma Local Municipality',
	        							'Inkosi Langalibalele Local Municipality',
	        							'Okhahlamba Local Municipality']
	        					},{
	        						name: 'Zululand District',
	        						subdists: ['AbaQulusi Local Municipality',
	        							'eDumbe Local Municipality',
	        							'Nongoma Local Municipality',
	        							'Ulundi Local Municipality',
	        							'uPhongolo Local Municipality']
	        					},{
	        						name: 'eThekwini Metropolitan',
	        						subdists: []
	        					}
	        				]
	        			},
	        			{
	        				name: 'Limpopo',
	        				districts: [
	        					{
	        						name: 'Capricorn District',
	        						subdists: ['Blouberg Local Municipality',
	        							'Lepelle-Nkumpi Local Municipality',
	        							'Molemole Local Municipality',
	        							'Polokwane Local Municipality']
	        					},{
	        						name: 'Mopani District',
	        						subdists: ['Ba-Phalaborwa Local Municipality',
	        							'Greater Giyani Local Municipality',
	        							'Greater Letaba Local Municipality',
	        							'Greater Tzaneen Local Municipality',
	        							'Maruleng Local Municipality']
	        					},{
	        						name: 'Sekhukhune District',
	        						subdists: ['Elias Motsoaledi Local Municipality',
	        							'Ephraim Mogale Local Municipality',
	        							'Fetakgomo Tubatse Local Municipality',
	        							'Makhuduthamaga Local Municipality']
	        					},{
	        						name: 'Vhembe District',
	        						subdists: ['Collins Chabane Local Municipality',
	        							'Makhado Local Municipality',
	        							'Musina Local Municipality',
	        							'Thulamela Local Municipality']
	        					},{
	        						name: 'Waterberg District',
	        						subdists: ['Bela-Bela Local Municipality',
	        							'Lephalale Local Municipality',
	        							'Modimolle-Mookgophong Local Municipality',
	        							'Mogalakwena Local Municipality',
	        							'Thabazimbi Local Municipality']
	        					},
	        				]
	        			},
	        			{
	        				name: 'Mpumalanga',
	        				districts: [
	        					{
	        						name: 'Ehlanzeni District',
	        						subdists: ['Bushbuckridge Local Municipality',
	        							'City of Mbombela Local Municipality',
	        							'Nkomazi Local Municipality',
	        							'Thaba Chweu Local Municipality',
	        							'Umjindi']
	        					},{
	        						name: 'Gert Sibande District',
	        						subdists: ['Chief Albert Luthuli Local Municipality',
	        							'Dipaleseng Local Municipality',
	        							'Dr Pixley Ka Isaka Seme Local Municipality',
	        							'Govan Mbeki Local Municipality',
	        							'Lekwa Local Municipality',
	        							'Mkhondo Local Municipality',
	        							'Msukaligwa Local Municipality']
	        					},{
	        						name: 'Nkangala District',
	        						subdists: ['Dr JS Moroka Local Municipality',
	        							'Emakhazeni Local Municipality',
	        							'Emalahleni Local Municipality',
	        							'Steve Tshwete Local Municipality',
	        							'Thembisile Hani Local Municipality',
	        							'Victor Khanye Local Municipality']
	        					},
	        				]
	        			},
	        			{
	        				name: 'North West',
	        				districts: [
	        					{
	        						name: 'Bojanala Platinum District',
	        						subdists: ['Kgetlengrivier Local Municipality',
	        							'Madibeng Local Municipality',
	        							'Moretele Local Municipality',
	        							'Moses Kotane Local Municipality',
	        							'Rustenburg Local Municipality']
	        					},{
	        						name: 'Dr Kenneth Kaunda District',
	        						subdists: ['City of Matlosana Local Municipality',
	        							'JB Marks Local Municipality',
	        							'Maquassi Hills Local Municipality']
	        					},{
	        						name: 'Dr Ruth Segomotsi Mompati District',
	        						subdists: ['Greater Taung Local Municipality',
	        							'Kagisano-Molopo Local Municipality',
	        							'Lekwa-Teemane Local Municipality',
	        							'Mamusa Local Municipality',
	        							'Naledi Local Municipality']
	        					},{
	        						name: 'Ngaka Modiri Molema District',
	        						subdists: ['Ditsobotla Local Municipality',
	        							'Mahikeng Local Municipality',
	        							'Ramotshere Moiloa Local Municipality',
	        							'Ratlou Local Municipality',
	        							'Tswaing Local Municipality']
	        					},
	        				]
	        			},
	        			{
	        				name: 'Northern Cape',
	        				districts: [
	        					{
	        						name: 'Frances Baard District',
	        						subdists: ['Dikgatlong Local Municipality',
	        							'Magareng Local Municipality',
	        							'Phokwane Local Municipality',
	        							'Sol Plaatje Local Municipality']
	        					},{
	        						name: 'John Taolo Gaetsewe District',
	        						subdists: ['Ga-Segonyana Local Municipality',
	        							'Gamagara Local Municipality',
	        							'Joe Morolong Local Municipality']
	        					},{
	        						name: 'Namakwa District',
	        						subdists: ['Hantam Local Municipality',
	        							'Kamiesberg Local Municipality',
	        							'Karoo Hoogland Local Municipality',
	        							'Khai-Ma Local Municipality',
	        							'Nama Khoi Local Municipality',
	        							'Richtersveld Local Municipality']
	        					},{
	        						name: 'Pixley Ka Seme District',
	        						subdists: ['Emthanjeni Local Municipality',
	        							'Kareeberg Local Municipality',
	        							'Renosterberg Local Municipality',
	        							'Siyancuma Local Municipality',
	        							'Siyathemba Local Municipality',
	        							'Thembelihle Local Municipality',
	        							'Ubuntu Local Municipality',
	        							'Umsobomvu Local Municipality']
	        					},{
	        						name: 'ZF Mgcawu District',
	        						subdists: ['!Kheis Local Municipality',
	        							'Dawid Kruiper Local Municipality',
	        							'Kai !Garib Local Municipality',
	        							'Kgatelopele Local Municipality',
	        							'Tsantsabane Local Municipality']
	        					},
	        				]
	        			},
	        			{
	        				name: 'Western Cape',
	        				districts: [
	        					{
	        						name: 'Cape Winelands District',
	        						subdists: ['Breede Valley Local Municipality',
	        							'Drakenstein Local Municipality',
	        							'Langeberg Local Municipality',
	        							'Stellenbosch Local Municipality',
	        							'Witzenberg Local Municipality']
	        					},{
	        						name: 'Central Karoo District',
	        						subdists: ['Beaufort West Local Municipality',
	        							'Laingsburg Local Municipality',
	        							'Prince Albert Local Municipality']
	        					},{
	        						name: 'Garden Route District',
	        						subdists: ['Bitou Local Municipality',
	        							'George Local Municipality',
	        							'Hessequa Local Municipality',
	        							'Kannaland Local Municipality',
	        							'Knysna Local Municipality',
	        							'Mossel Bay Local Municipality',
	        							'Oudtshoorn Local Municipality']
	        					},{
	        						name: 'Overberg District',
	        						subdists: ['Cape Agulhas Local Municipality',
	        							'Overstrand Local Municipality',
	        							'Swellendam Local Municipality',
	        							'Theewaterskloof Local Municipality']
	        					},{
	        						name: 'West Coast District',
	        						subdists: ['Bergrivier Local Municipality',
	        							'Cederberg Local Municipality',
	        							'Matzikama Local Municipality',
	        							'Saldanha Bay Local Municipality',
	        							'Swartland Local Municipality']
	        					},{
	        						name: 'City of Cape Town Metropolitan',
	        						subdists: []
	        					},
	        				]
	        			}
	        		]
            	},
            	{
            		name: 'Eswatini',
            		states: [
	        		]
            	},
            	{
            		name: 'United States',
            		states: [
            			{
            				name: 'CA',
            				districts: []
            			}
	        		]
            	}
        	]
            
        }
    },
    mounted() {
        this.loadPoi()
    },
    methods: {
        loadPoi: function() {
            var uuid = this.$route.params.uuid
            if (uuid && uuid != 'new') {
                this.doGet(`${this.baseURL}/poi/${this.$route.params.uuid}`, (resp, data) => {
                    this.poi = data
                    if (this.poi.servicesProvided == null) {
                    	this.poi.servicesProvided = []
                    }
                })
            } else {
                 this.poi = {
                    location: {
                        latitude: null,
                        longitude: null,
                    },
                    daysOpen: [],
                    categories: [],
                    servicesProvided: []
                }
            }
        },
        
        isGov: function() {
        	return this.poi.type == 'Government'
        },
        
//        countries: function() {
//        	return Object.keys(countryMap);
//        },
        
        stateOptions: function() {
        	var selecteCountry = this.poi.country;
        	if (selecteCountry && selecteCountry != null) {
        		var i;
        		for (i = 0; i < this.countryMap.length; i++) {
        			var item = this.countryMap[i];
        			if (item.name == selecteCountry) {
        				return item.states;
        			}
        		}
        	} else {
        		return []
        	}
        },
        
        districtOptions: function() {
        	var selectedState = this.poi.state;
        	if (selectedState && selectedState != null) {
        		var i;
        		var states = this.stateOptions()
        		for (i = 0; i < states.length; i++) {
        			var item = states[i];
        			if (item.name == selectedState) {
        				return item.districts;
        			}
        		}
        	} else {
        		return []
        	}
        },
        
        subdistOptions: function() {
        	var district = this.poi.district;
        	if (district && district != null) {
        		var i;
        		var districts = this.districtOptions()
        		for (i = 0; i < districts.length; i++) {
        			var item = districts[i];
        			if (item.name == district) {
        				return item.subdists;
        			}
        		}
        	} else {
        		return []
        	}
        },
        
        doSavePOI: function(evt) {
        	var pthis = this;
        	console.log(this.poi)
        	var url = this.$store.state.webservice + '/poi';
        	this.doPost(url, this.poi, function(resp, data) {
				  try {
				    pthis.notifyMessage("Sent");
				    pthis.poi = data;
				  } catch (err) {
				    pthis.log('error: ' + err)
				    pthis.handleError(err);
				  } 
	        }, function(resp, err) {
	          pthis.log('error: ' + err)
	          pthis.handleError('An Error Occured: ' + resp.body.data);
	        })
        },
        
        goBack() {
            this.$router.go(-1)
        },

        onAddressSelected: function() {
        },
    },
}
</script>