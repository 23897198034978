<template>
    <div>
        <el-container>
            <el-header class="site-header" height="auto">
                <siteheader parentName="Home"></siteheader>
            </el-header>

            <el-breadcrumb class="navMenu" separator-class="el-icon-arrow-right">
                <el-breadcrumb-item
                    icon="el-icon-location"
                    v-for="item in breadcrumbItems"
                    :key="item.text"
                    :to="item.to"
                >{{item.text}}</el-breadcrumb-item>
            </el-breadcrumb>

            <el-main>
                <h3>Manage Locations</h3>
                <div id="homeContent" v-if="hasAuth">
                    <mapTab activeTab="map"></mapTab>
                    <div class="map-area">
                        <div class="map" ref="map"></div>
                    </div>
                </div>
            </el-main>
        </el-container>
    </div>
</template>

<script>
import siteheader from '../components/site-header'
import mapTab from '../components/mapTab'
import utils from '../services/utils.js'
import moment from 'moment'
import jquery from 'jquery'

export default {
    name: 'Map',
    mixins: [utils],
    components: {
        siteheader: siteheader,
        mapTab: mapTab,
    },
    data() {
        return {
            baseURL: `${this.$store.state.webservice}/poi/`,
            keyword: '',
            activedPOI: {},
            markers: {},
            newMarker: null,
            onClickMapListener: null,
            breadcrumbItems: [
                {
                    text: 'Home',
                    active: false,
                },
                {
                    text: 'Manage Locations',
                    active: true,
                },
            ],
            loginUser: {},
            isAdmin: false,
            loginOrg: {},
            isCtaop: false,
        }
    },
    mounted() {
        this.loginUser = this.getLoginUser()
        this.isAdmin = this.loginUser.userType == 'Admin'
        this.loginOrg = this.getLoginOrg()
        this.isCtaop = this.loginOrg.uuid == 'ctaopUuid'
        this.initMap()
    },
    beforeDestroy: function() {
        this.onDestroyMap()
    },
    computed: {
        hasAuth() {
            return this.hasAccessToken()
        },
        userRole() {
            return this.loginUserType()
        },
        path() {
            return this.$router.currentRoute.path
        },
    },

    methods: {
        onTabClick(tab, evt) {
            if (tab != this.ativeTabName) {
                var login = this.getLoginUser()
                this.$router.push({
                    name: 'poi',
                    params: { loginUser: login },
                    query: { orgUuid: login.orgUuid },
                })
            }
        },
        initMap() {
            let container = this.$refs.map
            let viewHeight = window.visualViewport.height
            let pageHeight = document.body.clientHeight
            container.style.height = `${viewHeight - pageHeight - 5}px`
            container.style.position = 'relative'
            container.style.overflow = 'hidden'
            if (!window.map) {
                let map = new google.maps.Map(container, {
                    center: { lat: -33.9648017883, lng: 18.6016998291 },
                    zoom: 12,
                    disableDoubleClickZoom: true,
                })
                let mapService = new google.maps.places.PlacesService(map)
                window.map = map
                window.mapService = mapService
            } else {
                for (var i = 0; i < window.mapNodes.length; i++) {
                    container.appendChild(window.mapNodes[i])
                }
                window.map.setCenter({ lat: -33.9648017883, lng: 18.6016998291 })
                window.map.setZoom(12)
            }

            this.loadMarkers()
        },
        loadMarkers() {
            let self = this
            this.doGet(this.baseURL, (resp, data) => {
                for (let index = 0; index < data.length; index++) {
                    let poi = data[index]
                    let marker = new google.maps.Marker({
                        position: {
                            lat: poi.location.latitude,
                            lng: poi.location.longitude,
                        },
                        icon: './images/pin.png',
                        map: window.map,
                        data: poi,
                    })
                    google.maps.event.addListener(marker, 'click', function() {
                        let mark = this
                        self.onShowMarker.apply(self, [mark])
                    })
                    self.markers[poi.uuid] = marker
                }
            })
        },
        onShowMarker(marker) {
            this.activedPOI = marker.data
            let position = {
                lat: marker.data.location.latitude,
                lng: marker.data.location.longitude,
            }
            
            // not working ... dialog is undefined            
//            let dialog = this.$refs.dialog   

			let facilityName = marker.data.facilityName + " ....";        
            this.activeInfoWindow = new google.maps.InfoWindow({
                content: facilityName
            })
//
            this.infoWindowListener = google.maps.event.addListener(
                this.activeInfoWindow,
                'closeclick',
                this.onCancel
            )
//
            this.activeInfoWindow.open(window.map, marker)
//            dialog.className = 'poi-dialog'
        },
        onSearchPOI() {
            // var request = {
            //     location: this.keyword,
            //     radius: '500',
            // }
            // window.mapService.nearbySearch(request, function(results, status) {
            // })
        },
        onDestroyMap() {
            if (window.map) {
                window.mapNodes = this.$refs.map.childNodes
                this.onClickMapListener && google.maps.event.removeListener(this.onClickMapListener)
                this.onCancel()
            }
        },
        handleError(err) {
            // this.log('error: ' + err)
            this.errMessage = err
            this.notifyError(err)
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.fontSizelarge {
    font-size: large;
}

#homeContent {
    margin: auto;
    text-align: center;
}

.el-breadcrumb {
    padding: 20px;
}
.map-area {
    position: relative;
}
.map-search-input {
    position: absolute;
    top: 0;
    right: 0;
    margin: 10px 40px 0 0;
}
.invisiable {
    position: absolute;
    top: -5000px;
    right: -5000px;
}
.show {
    display: block;
}
.poi-dialog {
    width: 480px;
    position: relative;
    margin-top: 30px;
}
button.gm-ui-hover-effect {
    display: none;
}

.map button.gm-ui-hover-effect {
    top: 0 !important;
    right: 12px !important;
}
.map button.gm-ui-hover-effect img {
    width: 24px !important;
    height: 24px !important;
}
</style>
