<template>
<div class="login-form">

<!--  <h1>Where To Care</h1> -->
  
  <div class="logo">
  </div>

  <el-form ref="form" @submit.native.prevent="handleLogin"> 
    <span> <el-alert type="error" v-if="errMessage.length" :title="errMessage"></el-alert></span> 
    <el-form-item> 
        <el-input placeholder="Username" v-model="username"></el-input> 
    </el-form-item> 
    <el-form-item> 
        <el-input placeholder="Password" type="password" v-model="password"></el-input>
    </el-form-item>   
    <el-form-item> 
      <el-button type="primary" native-type="submit" :loading="loading" v-bind:disabled="formReady()">Login</el-button> 
    </el-form-item> 
  </el-form>

</div>
</template>


<script>

import utils from '../services/utils.js'

export default {
  name: 'Login',
  mixins: [utils],
  data () {
    return {
        username: '',
        password: '',
        errMessage: '',
        loading: false,
        
        form: {
            name: '',
            region: '',
            date1: '',
            date2: '',
            delivery: false,
            type: [],
            resource: '',
            desc: ''
          }
   
    }
  },
  computed: {

  },
  created() {
    
  },
  mounted() {
    this.removeLoginUser();
  },
  methods : { 
      formReady(){
          if(this.username != '' && this.password != '') {
              return false;
          }
          return true;
      },
      handleLogin() {
        this.loading = true;

        // get user , set Auth
        var loginEndpoint = this.$store.state.webservice + '/login';
        var body = {
            'uid' : this.username,
            'pwd' : this.password
        };
        
        var pthis = this;
        this.doPost(loginEndpoint, body, function(resp, data) {
          pthis.loading = false;
          data.pwdHash = null;
          var user = data;  // cache the whole object in case we need to update it.
          //user.hasAuth = true; // TODO: better make it a computed field from accessToken
          pthis.$store.commit('updateUser', user); 
          pthis.saveLoginUser(user);          
          pthis.getOrg(user);          
        }, function(resp, err) {
          console.dir(resp);
          pthis.handleError(err);
          pthis.loading = false;
        });
      },
      
      getOrg(user) {
        var uuid = user.orgUuid
        this.log('getOrg:' + uuid)
        if(!uuid) {
          this.handleError('Invalid orgUuid.');
          return;
        }
        
        var url = this.$store.state.webservice + '/org?uuid=' + uuid;
        var pthis = this;
        this.doGet(url, function(resp, data) {
          try {
            pthis.saveLoginOrg(data);            
            pthis.$router.push({name: 'home', params: {loginUser: user, org: data}});
          } catch (err) {
            pthis.log('error: ' + err)
            pthis.handleError(err);
          }         
        }, function(resp, err) {
          pthis.log('error: ' + resp)
          pthis.handleError('An Error Occured: ' + resp.body.data);
        })
      },
      handleError(err) {
        // this.log('error: ' + err)
        this.errMessage = err;
        this.notifyMessage(err, 'Error', 'error');
      }

  }
}
</script>

<style scoped>
.login-form {
	max-width: 420px;
	margin: auto;
  margin-top: 40px;
  padding:40px;
}

.logo {
  width: 333px;
  height: 225px;
  background-repeat:no-repeat;  
  background-image: url('../assets/wheretocare-logo.png');
  background-size: cover;
  margin: auto;
  margin-top: 40px;
  margin-bottom: 40px;
}
</style>