<template>
	<div class="list-view">

		<h2>{{title}}</h2>

		<el-row  type="flex">
			<el-col :span="6"></el-col>

			<el-col :span="12" style="line-height:36px">Select an item to manage:</el-col>

			<el-col :span="6" style="text-align:right">
				<el-button class="add" type="primary" icon="el-icon-plus" title="Add New" @click="addItem()">Add</el-button>
			</el-col>
		</el-row>

		<ul class="list-view">
			<li v-for="row in data" v-if="row.uuid != self">
				<router-link :to="{ name : path, params: {item: row}, query: {uuid: row.uuid} }">{{row.name}} {{row.uname}}</router-link>
			</li>
		</ul>

	</div>
</template>



<script>

export default {
  name: 'listViewer',
  props: ['data', 'title', 'path', 'self'],
  methods: {
  	addItem() {
      this.$router.push({name: this.path, params: {item: {}}}); // passing object to next vue
  	}
  }
}
</script>


<style scoped>

.list-view {
	margin:0;
	margin-top: 8px;
	padding: 0;
	list-style: none;
}

.list-view > li {
	margin: 0;
	padding: 0;
}
.list-view > li:first-child {
	border-top: 1px solid #DDD;
}

.list-view > li > a {
	display: block;
	margin: 0;
	padding: 6px;
	border-bottom: 1px solid #DDD;
	text-decoration: none;
	color: #444;
	font-weight: 700;
}
.list-view > li > a:hover {
	background-color: #E5E5E5;
}

.add {

}

</style>