import Vue from 'vue'
import moment from 'moment'

export default {
    methods: {
        saveLoginUser: function(user) {
            this.$session.set('loginUser', user)
        },

        getLoginUser: function() {
            var u = this.$session.get('loginUser')
            return u
        },

        removeLoginUser: function() {
            this.$session.remove('loginUser')
            this.$session.remove('loginOrg')
        },

        saveLoginOrg: function(org) {
            this.$session.set('loginOrg', org)
        },

        getLoginOrg: function() {
            var u = this.$session.get('loginOrg')
            return u
        },

        getAccessToken: function() {
            var u = this.getLoginUser()
            if (u) {
                return u.accessToken
            } else {
                return null
            }
        },

        hasAccessToken: function() {
            var u = this.getAccessToken()
            return u && u.length > 0
        },

        loginUserType: function() {
            var u = this.getLoginUser()
            if (u) {
                return u.userType
            } else {
                return null
            }
        },

        notifyMessage(msg, title, type) {
            if (!type) {
                type = 'success'
            }

            if (!title) {
                type = 'Success'
            }

            var mills = 2500
            if (type === 'error') {
                mills = 4000
            }
            this.$notify({
                title: title,
                message: msg,
                type: type,
                position: 'bottom-right',
                duration: mills,
            })
        },

        notifyError(msg) {
            this.notifyMessage(msg, 'Error', 'error')
        },

        log(obj) {
            console.dir(obj)
        },

        timestampToDateString(ts) {
            if (ts) {
                return new Date(ts).toISOString().substring(0, 10)
            }
            return null
        },
        disableInputs() {
            var allInputs = document.getElementsByTagName('input')
            var n = allInputs.length
            this.log('allInputs: ' + n)
            var i
            for (i = 0; i < n; i++) {
                allInputs[i].readOnly = true
            }

            var allTas = document.querySelectorAll('textarea')
            var n = allTas.length
            for (i = 0; i < n; i++) {
                allTas[i].readOnly = true
            }
        },

        periodRangeDisplay(firstDate, mDuration) {
            if (firstDate) {
                // 2018: January 1 to June 30
                var beginDt = moment(firstDate, 'YYYY-MM-DD')
                var endDt = moment(firstDate, 'YYYY-MM-DD')
                    .add(mDuration, 'months')
                    .subtract('1', 'days')
                var from = beginDt.format('MMM D')
                this.log(from)
                var to = endDt.format('MMM DD')
                this.log(to)
                return from + ' to ' + to
            }
        },

        ppnPeriodFullYearDisplay(timestamp) {
            this.log(timestamp)
            var ppnPeriod = this.timestampToDateTimeString(timestamp)

            var year = ppnPeriod.substring(0, 4)
            return 'Jan 1 to Dec 31, ' + year
        },

        ppnPeriodRangeYearDisplay(timestamp, mDuration) {
            this.log(timestamp)
            var ppnPeriod = this.timestampToDateTimeString(timestamp)
            if (!mDuration) mDuration = 6
            var year = ppnPeriod.substring(0, 4)
            return this.periodRangeDisplay(ppnPeriod, mDuration) + ', ' + year
        },

        qcPeriodRangeYearDisplay(timestamp) {
            var period = this.timestampToDateTimeString(timestamp)
            var duration = 3
            var year = period.substring(0, 4)
            return this.periodRangeDisplay(period, duration) + ', ' + year
        },

        getMoment(date, unit) {
            var d = moment(date, 'YYYY-MM-DD')
            this.log(d)
            var n = d.month()
            this.log(n)
            return n
        },

        getUtcMonthFrom(timestamp) {
            //      var dt = moment(timestamp);
            //      this.log('dt: ' + dt);
            //      var m = dt.month();  // 0 to 11
            //      this.log('m: ' + m);
            //      var b = (mm > 0);
            //      this.log(b ? "true" : "false");
            var dt = new Date(timestamp)
            var m = dt.getUTCMonth()
            return m
        },

        timestampToDateTimeString(ts) {
            try {
                return new Date(ts).toISOString()
            } catch (err) {
                this.log(err)
                this.log('ts: ' + ts)
                return '?'
            }
        },

        readonly() {
            var elements = document.querySelectorAll('textarea')
            for (var i = 0; i < elements.length; i++) {
                var newElement = document.createElement('p')
                newElement.className = 'printable'
                newElement.innerHTML = elements[i].value
                elements[i].style = 'display: none'
                elements[i].parentNode.appendChild(newElement)
            }
        },

        viewAsForm() {
            var elements = document.querySelectorAll('.printable')
            for (var i = 0; i < elements.length; i++) {
                elements[i].remove()
            }

            var elements = document.querySelectorAll('textarea')
            for (var i = 0; i < elements.length; i++) {
                elements[i].style = 'display: block'
            }
        },

        doGet: function(url, okCallback, errorCallback, config) {
            if (!config) {
                config = {
                    headers: {
                        accessToken: this.getAccessToken(),
                    },
                }
            }

            this.$http.get(url, config).then(
                response => {
                    if (response.body.rc == 1) {
                        okCallback(response, response.body.data)
                    } else {
                        console.log(response)
                        errorCallback(response, response.bodyText)
                    }
                },
                response => {
                    // error callback
                    console.error(response)
                    errorCallback(response)
                }
            )
        },

        doPost: function(url, postData, okCallback, errorCallback, config) {
            if (!config) {
                config = {
                    headers: {
                        accessToken: this.getAccessToken(),
                    },
                }
            }

            this.$http.post(url, postData, config).then(
                response => {
                    if (response.body.rc == 1) {
                        okCallback(response, response.body.data)
                    } else {
                        console.log(response)
                        errorCallback(response, response.bodyText)
                    }
                },
                response => {
                    // error callback
                    console.error(response)
                    errorCallback(response)
                }
            )
        },

        doPut: function(url, data, okCallback, errorCallback, config) {
            if (!config) {
                config = {
                    headers: {
                        accessToken: this.getAccessToken(),
                    },
                }
            }

            this.$http.put(url, postData, config).then(
                response => {
                    if (response.body.rc == 1) {
                        okCallback(response, response.body.data)
                    } else {
                        console.log(response)
                        errorCallback(response, response.bodyText)
                    }
                },
                response => {
                    // error callback
                    console.error(response)
                    errorCallback(response)
                }
            )
        },

        doDelete: function(url, okCallback, errorCallback, config) {
            if (!config) {
                config = {
                    headers: {
                        accessToken: this.getAccessToken(),
                    },
                }
            }

            this.$http.delete(url, null, config).then(
                response => {
                    if (response.body.rc == 1) {
                        okCallback(response, response.body.data)
                    } else {
                        console.log(response)
                        errorCallback(response, response.bodyText)
                    }
                },
                response => {
                    // error callback
                    console.error(response)
                    errorCallback(response)
                }
            )
        },
    },
}
