<template>
<div>
  <el-container>

    <el-header class="site-header" height="auto">
        <siteheader></siteheader>
    </el-header>

    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item icon="el-icon-location" v-for="item, key in breadcrumbItems" :key="item.text" :to="item.to">{{item.text}}</el-breadcrumb-item>
    </el-breadcrumb>    
    
    <el-main>
    
      <el-alert type="error"  v-if="errMessage.length" :title="errMessage"></el-alert>
      
      <div class="detail-view">
      
       <el-card class="box-card">
           <div slot="header" class="clearfix">
             <span  style="text-align: left">{{title}}</span>
           </div>
      
           <div class="line"></div>
      
           <el-form ref="form" @submit.native.prevent="doSaveUser" label-position="Left" label-width="160px">
          
             <el-form-item label="User name"><el-input placeholder="Username" v-model="user.uname" type="text" autocomplete="off"></el-input></el-form-item>
             <el-form-item label="Password"><el-input placeholder="Password" type="password" v-model="user.pwdHash"></el-input></el-form-item>
             <el-form-item label="First Name"><el-input placeholder="Firstname" v-model="user.firstname" type="text" autocomplete="off"></el-input></el-form-item>
             <el-form-item label="Last Name"><el-input placeholder="Lastname" v-model="user.lastname" type="text" autocomplete="off"></el-input></el-form-item>

             <el-form-item label="Role">
                <el-select v-model="user.userType" placeholder="Select">
                  <el-option
                    v-for="item in userTypes"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
             </el-form-item>
             
            <el-form-item label="Organization" v-if="userUuid">
            	<el-row>
            		<el-col :span="20">
            			<el-input readonly v-model="org.name" type="text"></el-input>
            		</el-col>
            		<el-col :span="4">
            			<el-button @click="showOrgUpsert" type="primary" icon="el-icon-edit" plain>Update</el-button>
            		</el-col>
            	</el-row>
            </el-form-item>
           
            <el-form-item label="Last Seen"> 
              <div>
                <span class="demonstration"></span>
                <el-date-picker
                  v-model="user.lastActivityTimeStamp"
                  type="date"
                  value-format="timestamp"
                  readonly>
                </el-date-picker>
              </div>
            </el-form-item> 

             <b-container>
               <b-row>
                 <b-col v-if="userUuid"><el-button type="danger" icon="el-icon-delete" v-on:click="doDelete">Delete</el-button></b-col>
                 <b-col><el-button type="primary" icon="el-icon-check" v-on:click="doSaveUser">Save</el-button></b-col>
               </b-row>                            
             </b-container>
           </el-form>
         </el-card>

      </div>

    </el-main>

  </el-container>
</div>

</template>

<script>
import Vue from 'vue';
import siteheader from '../components/site-header';
import utils from '../services/utils.js'
import orgVue from './Org'


export default {
  name: 'User',
  mixins: [utils],
  components : {
    siteheader,orgVue
  },
  props: ['item'],
  data () {
    return {
      msg: '',
      errMessage: '',
      userTypes: [{
        value: 'Admin',
        label: 'Admin'
      }, {
        value: 'Regular',
        label: 'Regular'
      }],
      user: {}, // this.item, // init with the prop passed by the from-component}
      org: this.getLoginOrg()
    }
  },
  computed: {
    title: function () {
      // `this` points to the vm instance
      if(this.user && this.user.uname) {
        return this.user.uname;
      } else {
        return "Enter new User";
      }
    },
    userUuid: function () {
      if(this.user && this.user.uuid) {
        return this.user.uuid;
      } else {
        var quuid = this.$route.query.uuid;
        return quuid;
      }
    },
    breadcrumbItems: function() {
      var routeName = this.$route.name;
      
      var bcis;
      if(routeName == 'Profile') {
        bcis = [{
          text: 'Home',
          to: 'Home'
        }, {
          text: 'My Profile',
          active: true
        }];
      } else {
        bcis = [{
          text: 'Home',
          to: 'Home'
        }, {
          text: 'Manage Users',
          to: {"name": 'Users', "params": {"loginUser": this.getLoginUser()}, "query": {"orgUuid": this.getLoginUser().orgUuid}}
        }, {
          text: 'User',
          active: true
        }];
      }
      
      return bcis;
    }
  },
  
  created() {
    this.log(">>created");
    this.user = this.item;
    this.org = this.getLoginOrg();
  },
  mounted() {    
    this.log(this.user);
    if(this.userUuid) {
      if(this.user) {
        this.user.pwdHash = null;
      }
      this.getUser(this.userUuid);
    } else {
      this.notifyMessage("Create New User");
    }
  },
  methods : {
    
    getUser(uuid) {
      this.log('getUser:' + uuid)
      if(!uuid) {
        this.handleError('Invalid User UUID.');
        return;
      }
      
      var url = this.$store.state.webservice + '/user?uuid=' + uuid;
      
      var pthis = this;
      this.doGet(url, function(resp, data) {
        try {
          if(data) {
            pthis.user = data;
            pthis.user.pwdHash = null;
            
            pthis.getOrg(data.orgUuid);
            
          }
        } catch (err) {
          pthis.log('error: ' + err)
          pthis.handleError(err);
        }       
      }, function(resp, err) {
        pthis.log('error: ' + resp)
        pthis.handleError('An Error Occured: ' + resp.body.data);
      });
    },
    
    
    getOrg(uuid) {
      this.log('getOrg:' + uuid)
      if(!uuid) {
        this.handleError('Invalid Org UUID.');
        return;
      }
      
      var url = this.$store.state.webservice + '/org?uuid=' + uuid;
      
      var pthis = this;
      this.doGet(url, function(resp, data) {
        try {
          if(data) {
            pthis.org = data;
          }
        } catch (err) {
          pthis.log('error: ' + err)
          pthis.handleError(err);
        }       
      }, function(resp, err) {
        pthis.log('error: ' + resp)
        pthis.handleError('An Error Occured: ' + resp.body.data);
      });
    },
    
    showOrgUpsert() {
      var o = this.org; 
      // this.log(this.$route.path);
      var rname1 = this.$route.name;
      var rname2 = (rname1 == "Profile") ? 'MyOrg' : "UserOrg";
      
      this.$router.push({name: rname2, params: {item: o}, query: {uuid: o.uuid}}); // passing object to next vue
    },
    
    doSaveUser() {
      this.log(this.user);
      var login = this.getLoginUser();
      this.user.orgUuid = login.orgUuid;
      var url = this.$store.state.webservice + '/upsertUser';
      
      var pthis = this;
      this.doPost(url, this.user, function(resp, data) {
        try {
          pthis.user = data;              
          //this.$router.go(-1);
          pthis.user.pwdHash = null;
          pthis.notifyMessage("Saved");
          pthis.$router.go(-1);
        } catch (err) {
          pthis.log('error: ' + err)
          pthis.handleError(err);
        } 
      }, function(resp, err) {
        pthis.log('error: ' + err)
        pthis.handleError('An Error Occured: ' + resp.body.data);
      })
    },
        
    doDelete(event) {
      this.log(this.user);
      
      var url = this.$store.state.webservice + '/deleteUser';
      var pthis = this;
      this.doPost(url, this.user, function(resp, data) {
        try {
          pthis.notifyMessage("Deleted");
          pthis.$router.go(-1);
        } catch (err) {
          pthis.log('error: ' + err)
          pthis.handleError(err);
        } 
      }, function(resp, err) {
        pthis.log('error: ' + resp)
        pthis.handleError('An Error Occured: ' + resp);
      })
    },

    
    handleError(msg) {
      // this.log('error: ' + err)
      this.errMessage = msg;
      this.notifyError(msg);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>




</style>
