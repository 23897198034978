<template>
<div>
  <el-container>

    <el-header class="site-header" height="auto">
        <siteheader></siteheader>
    </el-header>

    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item icon="el-icon-location" v-for="item in breadcrumbItems" :key="item.text" :to="item.to">{{item.text}}</el-breadcrumb-item>
    </el-breadcrumb>    
  
    <el-main>
      <h3>Manage Users</h3>
      
      <el-alert type="error"  v-if="errMessage.length" :title="errMessage"></el-alert>
      <el-row  type="flex">
        <p></p>
      </el-row>
      <el-row  type="flex">
        <el-col :span="6"></el-col>
        <el-col :span="12" style="line-height:36px">Select to Edit:</el-col>
        <el-col :span="6" style="text-align:right">
          <el-button class="add" type="primary" icon="el-icon-plus" title="Add New" @click="showUser({})">Add</el-button>
        </el-col>
      </el-row>
      <el-row  type="flex">
      <el-table stripe :data="listData" @row-click="showUser">   
        <el-table-column align="center" type="index"></el-table-column> 
        <el-table-column align="left" width="160" prop="uname" label="Username"></el-table-column>
        <el-table-column align="left" prop="firstname" label="First name"></el-table-column>
        <el-table-column align="left" prop="lastname" label="Last name"></el-table-column>
        <el-table-column align="left" prop="userType" label="User Type"></el-table-column>
      </el-table>
      </el-row>      

    </el-main>

  </el-container>
</div>
</template>

<script>
import Vue from 'vue';

import siteheader from '../components/site-header';
import listViewer from '../components/listViewer';
import utils from '../services/utils.js'

export default {
  name: 'Users',
  mixins: [utils],
  components : {
    siteheader,
    listViewer
  },
  props: ['loginUser'],
  data () {
    return {
      msg: '',
      errMessage: '',
      listData: [],
      breadcrumbItems: [{
        text: 'Home',
        to: 'Home'
      }, {
        text: 'Manage Users',
        active: true
      }]
    }
  },
  computed: {
    orgUuid: function () {
      var quuid = this.$route.query.orgUuid;
      return quuid;
    }
  },
  mounted() {
    this.getUsers();
  },
  methods : {
    getUsers() {
      this.log('getUsers')
      var url = this.$store.state.webservice + '/users'+'?orgUuid='+this.orgUuid;
 
      var pSelf = this;
      this.doGet(url, function(resp, data) {
        pSelf.listData = data;
      }, function(resp, err) {
        pSelf.log(resp);
        pSelf.handleError(err);
      });
      
    },
    showUser: function(row, event, column) {
      //        <router-link :to="{ name : path, params: {item: row}, query: {uuid: row.uuid} }">{{row.name}} {{row.uname}}</router-link>      
      this.$router.push({ name : 'User', params: {item: row}, query: {uuid: row.uuid} });
    },    

    handleError(err) {
      // this.log('error: ' + err)
      this.errMessage = err;
      this.notifyMessage(err, 'Error', 'error');
    }

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>




</style>
